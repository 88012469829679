<template>
  <div>
    <div class="grid-stack"></div>

    <AreYouSureModal
      :data="removedWidgetData"
      :onClose="onClose"
      :removedUser="removedWidget"
    />
    <DashboardPreviewModal
      :widget="selectedWidget"
      :selectedWidgetType="selectedWidgetType"
      :isEdit="true"
      @closeSlider="closeSlider"
    ></DashboardPreviewModal>
    <div v-if="show" style="height: 60vh">
      <Loader :show="show"></Loader>
    </div>
    <Carousel v-if="!totalWidgets" />
  </div>
</template>

<script>
import GridStack from "gridstack/dist/es5/gridstack-all";
import Carousel from "@core/components/carousel/carousel.vue";
import "gridstack/dist/gridstack.css";
import "gridstack/dist/gridstack.min.css";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportService from "@/libs/api/report-service";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import WidgetDashBoard from "@/libs/dashboard/componets/index";
import chart from "./Chart.vue"; // Import the Vue component to be included
import * as Utils from "@/libs/utils";
import Vue from "vue";
import { customTooltipFunction } from "@/libs/dashboard/utils/customTooltips";
import { BCard, BIconTelephoneMinus } from "bootstrap-vue";
import EventBus from "@/EventBus";
import DashboardPreviewModal from "@/views/Dashboard/Consumer/DashboardPreviewModal.vue";

export default {
  components: {
    chart,
    BCard,
    AreYouSureModal,
    DashboardPreviewModal,
    Loader,
    Carousel
  },
  created() {
    EventBus.$on("add-dashboard-widget", this.addDashboardWidget);
    EventBus.$on("add-global-filter", this.addGlobalFilter);
  },
  mounted() {
    // Initialize Gridstack
    // window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // Add grid items
  },
  data() {
    return {
      grid: null,
      totalWidgets: 0,
      globalFilters: {
        // isAllUnitGroup: true,
        account_id: localStorage.getItem("USER_ACCOUNT_ID"),
        units: [],
        isAllUnit: true,
        unit_groups: []
      },
      selectedWidget: {},
      selectedWidgetType: "",
      removedWidgetData: { id: "", name: "" },
      isMobile: false,
      timeoutId: null,
      show: true,
      globalWidgets: [],
      defaultConfiguration:
        document.documentElement.clientWidth <= 991
          ? {
              h: 20,
              maxH: 20,
              maxW: 12,
              minH: 12,
              minW: 12,
              w: 12,
              x: 0,
              y: 0
            }
          : { maxH: 12, maxW: 12, minH: 6, minW: 12, x: 0, y: 0, w: 12, h: 12 },
      dashTranslations: {
        remove: this.$t("dashboard.remove") || "remove",
        edit: this.$t("dashboard.edit") || "edit",
        move: this.$t("dashboard.move") || "move",
        no_dash_message:
          this.$t("NODashboardDataMessage") || "No Data Available"
      }
    };
  },
  methods: {
    handleResize() {
      this.isMobile =
        document.documentElement.clientWidth <= 991 ? true : false;
      // console.log(document.documentElement.clientWidth);
      this.defaultConfiguration = this.isMobile
        ? { maxH: 20, maxW: 12, minH: 8, minW: 12, x: 0, y: 0, w: 12, h: 12 }
        : { maxH: 6, maxW: 6, minH: 3, minW: 3, x: 0, y: 0, w: 6, h: 6 };
      this.grid = GridStack.init({
        acceptWidgets: true, // Allow dropping of external widgets
        width: 12, // Default number of columns
        float: true, // Allow items to be freely positioned
        compact: true,
        resizable: true,
        margin: "2px",
        ...this.defaultConfiguration,
        handle: ".widget-drag"
        // Options for resizing
      });
      const me = this;
      this.grid.on("dragstop", function (event, el) {
        me.setChartSize(el);
        me.resizeWidget(me);
        if (el.__vue__.$props) {
          el.__vue__.$props.dragEnabled = false;
        } else if (
          el &&
          el.__vue__ &&
          el.__vue__.$children &&
          el.__vue__.$children.length &&
          el.__vue__.$children[0]
        ) {
          el.__vue__.$children[0].$props.dragEnabled = false;
        }
      });
      this.grid.on("added", function (event, el) {
        if (el && el.length) me.setChartSize(el[0].el);
      });
      this.grid.on("change", function (event, items) {});

      this.grid.on("resizestop", function (event, el) {
        me.setChartSize(el);
        me.resizeWidget(me);
      });
      this.apply();
    },
    resizeWidget(me) {
      const key = this.isMobile ? "mobile" : "web";
      const widgetSettings = me.grid.engine.nodes.map((e) => {
        let keyValue = {
          web: {
            h: e.h,
            maxH: e.maxH || this.defaultConfiguration.maxH,
            maxW: e.maxW || this.defaultConfiguration.maxW,
            minH: e.minH || this.defaultConfiguration.minH,
            minW: e.minW || this.defaultConfiguration.minW,
            w: e.w,
            x: e.x,
            y: e.y === -Infinity ? 0 : e.y
          }
        };
        if (key === "mobile") {
          keyValue = {
            mobile: {
              h: 20,
              maxH: 20,
              maxW: 12,
              minH: 12,
              minW: 12,
              w: 12,
              x: e.x,
              y: e.y === -Infinity ? 0 : e.y
            }
          };
        }
        return {
          id: e.id,
          key: key,
          settings: keyValue
        };
      });
      if (widgetSettings && widgetSettings.length > 1) {
        me.debounce(me, widgetSettings, 500);
      }
    },
    onCompactClick() {
      this.grid.compact();
    },
    setChartSize(el) {
      if (
        el.__vue__.hasOwnProperty("updateHeightWidth") &&
        typeof el.__vue__.updateHeightWidth === "function"
      ) {
        el.__vue__.updateHeightWidth(
          `${el.clientHeight}px`,
          `${el.clientWidth}px`
        );
      } else if (
        el &&
        el.__vue__ &&
        el.__vue__.$children &&
        el.__vue__.$children.length &&
        el.__vue__.$children[0].hasOwnProperty("updateHeightWidth") &&
        typeof el.__vue__.$children[0].updateHeightWidth === "function"
      ) {
        el.__vue__.$children[0].updateHeightWidth(
          `${el.clientHeight}px`,
          `${el.clientWidth}px`
        );
      }
      this.onCompactClick();
    },
    updateGlobalFilterArray(widget) {
      if (!widget?.id) {
        return;
      }
      const index =
        this.globalWidgets?.findIndex((w) => w.id === widget.id) ?? -1;
      if (index >= 0 && !widget?.parameters?.is_global_filter) {
        this.globalWidgets.splice(index, 1);
      } else if (index == -1 && widget?.parameters?.is_global_filter) {
        this.globalWidgets.push(widget);
      }
    },
    async apply() {
      try {
        let response = await new ReportService().getSavedWidgets({
          page: 1,
          page_size: 50
        });
        this.show = false;
        if (response && response.data && response.data) {
          let widgets = response.data.list || [];
          widgets = widgets.sort((a, b) => {
            return a.settings.web.y - b.settings.web.y;
          });

          this.globalWidgets = widgets.filter(
            (e) =>
              e &&
              e.parameters &&
              e.parameters.is_global_filter &&
              !e.disabled_account_units
          );
          this.totalWidgets = response.data.pagination.total_records || 0;
          widgets.forEach(async (item) => {
            setTimeout(async () => {
              if (item.use_unit_filters && item.use_unit_filters.parameters) {
                item.use_unit_filters.parameters.forEach((e) => {
                  if (e.name === "date_range_dash") {
                    if (e.range) {
                      e.value = this.getCustomRange(e.range);
                    }
                    if (
                      !e.range &&
                      [
                        "Today",
                        "Yesterday",
                        "ThisWeek",
                        "LastWeek",
                        "ThisMonth",
                        "LastMonth",
                        "ThisYear"
                      ].includes(e.value)
                    ) {
                      e.value = this.getCustomRange(e.value);
                    }
                  }
                });
              }

              await this.getWidgets(item);
            }, 100);
          });
        } else if (response && response.error && response.error.message) {
        }
      } catch (err) {
        // console.log(err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async reorderingWidgets(items) {
      try {
        this.show = true;
        let response = await new ReportService().reorderingWidgets({
          renderingData: items
        });
        this.show = false;
        if (response && response.data && response.data) {
          this.totalWidgets = this.totalWidgets + 1;
        } else if (response && response.error && response.error.message) {
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    debounce(instance, data, delay) {
      if (instance.timeoutId) {
        clearTimeout(instance.timeoutId);
      }
      instance.timeoutId = setTimeout(() => {
        instance.reorderingWidgets(data);
      }, delay);
    },
    async getWidgets(widget) {
      try {
        let charData = [];
        const selectedWidget = WidgetDashBoard.find(
          (e) => e.name === widget.component
        );
        if (selectedWidget && selectedWidget.type) {
          charData = JSON.parse(JSON.stringify(selectedWidget));
        }
        let options = [];
        let units = { is_all: true, selected: [] };
        const me = this;
        const componentInstance = new Vue({
          render: (h) =>
            h(chart, {
              props: {
                widget: {
                  ...widget
                },
                type: widget.component,
                grid: me.grid,
                dragEnabled: false,
                removeWidget: me.removeWidget,
                updateWidget: me.updateWidget,
                dragWidgetClick: me.dragWidgetClick,
                updateDateFilter: me.updateDateFilter,
                dashTranslations: me.dashTranslations
              }
            })
        }).$mount();

        if (
          widget.use_unit_filters &&
          widget.use_unit_filters.parameters &&
          widget.use_unit_filters.parameters.length
        ) {
          widget.use_unit_filters.parameters.forEach((e) => {
            if (e.name === "date_range_dash" && e.range) {
              if (e.range) {
                e.value = this.getCustomRange(e.range);
              }
              if (
                e.name === "date_range_dash" &&
                !e.range &&
                [
                  "Today",
                  "Yesterday",
                  "ThisWeek",
                  "LastWeek",
                  "ThisMonth",
                  "LastMonth",
                  "ThisYear"
                ].includes(e.value)
              ) {
                e.value = this.getCustomRange(e.value);
              }

              // componentInstance.$children[0].ranges.unshift(de);
              componentInstance.$children[0].range = e.range;
            }
          });
          options =
            widget.use_unit_filters.parameters.map((e) => {
              const obj = {};
              obj.field = e.name;
              obj.value = e.value;
              return obj;
            }) || [];

          const unitSource = widget.use_unit_filters.parameters.find(
            (e) => e.name === "unit_source"
          );
          if (options && options.length) {
            options = options.filter((e) => e.name !== "unit_source");
          }

          if (unitSource && unitSource.value && unitSource.value.length) {
            units.selected = unitSource.value;
          }
        }

        if (
          widget.is_unit_lookup &&
          ((widget.units && widget.units.is_all) ||
            (widget.units &&
              widget.units.selected &&
              widget.units.selected.length))
        ) {
          units = widget.units;
        }

        let widgetReq = { widget_type: widget.widget_type };
        if (!widget.is_no_filter) {
          widgetReq = {
            options,
            widget_type: widget.widget_type,
            account_id: widget.account_id
          };
          if (
            widget &&
            widget.use_unit_filters &&
            widget.use_unit_filters.multiple_units
          ) {
            if (Array.isArray(units.selected)) {
              widgetReq.units = units;
            } else if (typeof units.selected === "string") {
              widgetReq.units = {
                is_all: false,
                selected: [units.selected]
              };
            } else {
              widgetReq.units = {
                is_all: true,
                selected: []
              };
            }
          } else {
            widgetReq.unit_id = widget.unit_id;
          }
          if (
            widget.is_unit_lookup &&
            ((widget.unit_groups && widget.unit_groups.is_all) ||
              (widget.unit_groups &&
                widget.unit_groups.selected &&
                widget.unit_groups.selected.length))
          ) {
            widgetReq.unit_groups = widget.unit_groups;
          }
        }

        const widgetConfig = this.isMobile
          ? widget.settings.mobile
          : widget.settings.web;
        let maxHeight = -Infinity;
        let maxHeightX = -Infinity;

        for (const node of this.grid.engine.nodes) {
          if (node.y > maxHeight) {
            maxHeight = node.y;
            maxHeightX = node.h;
          } else if (node.y === maxHeight && node.h > maxHeightX) {
            maxHeightX = node.h;
          }
        }
        let yaxis = 0;
        if (maxHeightX + maxHeight + 1) {
          yaxis = maxHeightX + maxHeight + 1;
        }
        this.grid.addWidget(componentInstance.$el, {
          ...widgetConfig,
          id: widget.id,
          y: yaxis,
          x: 0,
          margin: "2px"
        });
        // this.totalWidgets = this.totalWidgets + 1;
        componentInstance.$children[0].$props.isApiCall = true;
        // componentInstance.$forceUpdate();
        let response = await new ReportService().getWidgetsData(widgetReq);
        componentInstance.$children[0].$props.isApiCall = false;

        if (response && response.data && response.data.data) {
          let {
            series,
            categories,
            max_level,
            min_level,
            annotations,
            chartOptions,
            labels
          } = response.data.data;
          // const isNoData = response.data.is_no_data;
          componentInstance.$children[0].$props.isNoData = !series.some(
            (series) => {
              if (Array.isArray(series)) {
                return series.length > 0;
              } else if (
                typeof series === "object" &&
                series !== null &&
                "data" in series
              ) {
                return Array.isArray(series.data) && series.data.length > 0;
              } else {
                return Boolean(series); // To check if the series element is a non-zero number or truthy value
              }
            }
          );
          this.drawChart(
            {
              min_level,
              max_level,
              series,
              widget,
              charData,
              labels,
              annotations,
              categories,
              chartOptions
            },
            componentInstance
          );
          // widgetData.is_no_data = isNoData;
          // widgetData.is_dummy = isNoData;
        } else if (response && response.error && response.error.message) {
          // this.widgetData.is_no_data = true;
          componentInstance.$children[0].$props.isNoData = true;
          this.show = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        // console.log(err);
      }
    },
    drawChart(data, componentInstance, isAdd) {
      try {
        let {
          min_level,
          max_level,
          widget,
          series,
          charData,
          labels,
          annotations,
          categories,
          colors,
          chartOptions
        } = data;
        const minValue = Number(min_level) * 0.85;
        const maxValue = Number(max_level) * 1.1;
        this.show = false;
        const gridNode = this.grid.engine.nodes.find(
          (node) => node.id === widget.id
        );
        const colorsData = [];

        if (
          (widget.widget_type === "FuelConsumptionWidget" ||
            widget.type === "FuelConsumptionWidget") &&
          widget.component === "line"
        ) {
          series = series.map((e, index) => {
            if (
              e.config &&
              e.config.is_show_line &&
              e.config.lines &&
              e.config.lines.length
            ) {
              e.config.lines = e.config.lines.map((l, index) => {
                if (!l.color) {
                  l.color = Utils.COLOR[index + 10];
                }
                return l;
              });
            }
            if (e.color) {
              colorsData.push(e.color);
            } else {
              e.color = Utils.COLOR[index];
              colorsData.push(Utils.COLOR[index]);
            }

            return e;
          });
        } else if (
          widget.widget_type !== "FuelConsumptionWidget" &&
          widget.type !== "FuelConsumptionWidget" &&
          widget.component === "line"
        ) {
          charData.series = series;
          charData.chartOptions.xaxis = { categories };
          charData.chartOptions.chart.zoom = {
            enabled: false
          };
        }
        if (widget.component === "bar") {
          // const colors = Utils.randomDarkChartColors(series.length);
          charData.series = series;
          charData.chartOptions.xaxis.categories = categories;
          charData.chartOptions.colors = colors;
        } else if (widget.component === "donut") {
          // const colors = generateRandomColors(series.length);
          charData.series = JSON.parse(JSON.stringify(series));
          charData.chartOptions.labels = labels;
          // charData.chartOptions.colors = colors;
        }

        // charData.series = JSON.parse(JSON.stringify([]));
        // charData.series = JSON.parse(JSON.stringify(series));

        if (widget && widget.component == "table") {
          charData.chartOptions = JSON.parse(JSON.stringify(chartOptions));
          charData.series = JSON.parse(JSON.stringify(series));
        }
        charData.chartOptions.tooltip = {};
        // charData.chartOptions.colors = colorsData;
        if (
          widget &&
          !widget.is_no_filter &&
          (widget.widget_type === "FuelConsumptionWidget" ||
            widget.type === "FuelConsumptionWidget") &&
          widget.component === "line"
        ) {
          if (annotations) {
            charData.chartOptions.annotations = {
              xaxis: annotations.xaxis
            };
          }
          if (widget.widget_type === "FuelConsumptionWidget") {
            charData.chartOptions.yaxis.max = function (max) {
              if (this.zoomed) {
                return max;
              }
              return maxValue;
            };
            charData.chartOptions.tooltip = {
              x: {
                show: true,
                format: "dd MMM yy HH:mm:ss"
              },
              custom: customTooltipFunction
            };
            charData.chartOptions.yaxis.min = function (min) {
              if (this.zoomed) {
                return min;
              }
              return minValue;
            };
          }
        }

        if (widget && widget.component !== "table") {
          // widgetData = charData;
        }

        const me = this;

        if (isAdd) {
          if (gridNode) {
            this.grid.removeWidget(gridNode.el);
            this.grid.compact();
            this.totalWidgets = this.totalWidgets - 1;
          }
          let dateRangeData = {};
          if (widget.use_unit_filters && widget.use_unit_filters.parameters) {
            dateRangeData = widget.use_unit_filters.parameters.find(
              (e) => e.name === "date_range_dash" && e.range
            );
            if (dateRangeData && dateRangeData.name === "date_range_dash") {
              if (
                dateRangeData.name === "date_range_dash" &&
                dateRangeData.range
              ) {
                if (dateRangeData.range) {
                  dateRangeData.value = this.getCustomRange(
                    dateRangeData.range
                  );
                }
              }
            }
          }
          let isNoData = false;
          if (charData.series && charData.series.length) {
            if (widget.component === "donut") {
              isNoData = !charData.series.some((series) => {
                if (typeof series === "number") {
                  return series > 0;
                }
                return false;
              });
            } else {
              isNoData = false;
            }
          }
          const componentInstance = new Vue({
            render: (h) =>
              h(chart, {
                props: {
                  widget: {
                    ...charData,
                    ...widget,
                    maxValue: maxValue,
                    minValue: minValue
                  },
                  type: widget.component,
                  grid: me.grid,
                  dragEnabled: false,
                  removeWidget: me.removeWidget,
                  updateWidget: me.updateWidget,
                  dragWidgetClick: me.dragWidgetClick,
                  dashTranslations: me.dashTranslations,
                  updateDateFilter: me.updateDateFilter,
                  range:
                    dateRangeData && dateRangeData.range
                      ? dateRangeData.range
                      : null,
                  isNoData
                }
              })
          }).$mount();
          const widgetConfig = this.isMobile
            ? widget.settings.mobile
            : widget.settings.web;
          let maxHeight = -Infinity;
          let maxHeightX = -Infinity;

          for (const node of this.grid.engine.nodes) {
            if (node.y > maxHeight) {
              maxHeight = node.y;
              maxHeightX = node.h;
            } else if (node.y === maxHeight && node.h > maxHeightX) {
              maxHeightX = node.h;
            }
          }
          let yaxis = 0;
          if (this.grid.engine.nodes && this.grid.engine.nodes.length) {
            yaxis = maxHeightX + maxHeight + 1;
          }

          this.grid.addWidget(componentInstance.$el, {
            ...widgetConfig,
            id: widget.id,
            y: yaxis,
            x: 0,
            margin: "2px"
          });

          this.$nextTick(() => {
            const el = componentInstance.$children[0].$refs[`${widget.id}_id`];
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
          });

          this.totalWidgets = this.totalWidgets + 1;
          const key = this.isMobile ? "mobile" : "web";

          const widgetSettings = this.grid.engine.nodes.map((e) => {
            let grid = { ...e };
            if (grid.id === widget.id) {
              grid.y = maxHeightX + maxHeight + 1;
            }
            // return grid;
            const { h, maxH, maxW, minH, minW, w, x, y } = e;
            const defaultConfig = this.defaultConfiguration;

            const settings =
              key === "mobile"
                ? {
                    h: 20,
                    maxH: 20,
                    maxW: 12,
                    minH: 12,
                    minW: 12,
                    w: 12,
                    x,
                    y: y === -Infinity ? 0 : y
                  }
                : {
                    h,
                    maxH: maxH || defaultConfig.maxH,
                    maxW: maxW || defaultConfig.maxW,
                    minH: minH || defaultConfig.minH,
                    minW: minW || defaultConfig.minW,
                    w,
                    x,
                    y: y === -Infinity ? 0 : y
                  };

            return {
              id: grid.id,
              key,
              settings: { [key]: settings }
            };
          });

          // this.reorderingWidgets(widgetSettings);
          if (widgetSettings && widgetSettings.length > 1) {
            this.debounce(this, widgetSettings, 500);
          }

          this.resizeWidget(this);

          // this.grid.load();
        } else {
          try {
            componentInstance.$children[0].$props.isApiCall = false;
            componentInstance.$children[0].$props.isNoData =
              charData.series &&
              !charData.series.some((series) => {
                if (Array.isArray(series)) {
                  return series.length > 0;
                } else if (
                  typeof series === "object" &&
                  series !== null &&
                  "data" in series
                ) {
                  return Array.isArray(series.data) && series.data.length > 0;
                } else {
                  return Boolean(series); // To check if the series element is a non-zero number or truthy value
                }
              });
            componentInstance.$children[0].$props.widget = {
              ...componentInstance.$children[0].$props.widget,
              series: charData.series,
              chartOptions: charData.chartOptions,
              minValue: minValue,
              maxValue: maxValue
            };

            this.setChartSize(gridNode.el);
            this.grid.compact();
          } catch (err) {
            // console.log(err);
          }
        }
      } catch (err) {
        // console.log(err);
      }
    },
    async getWidgetsData(widget, instance, isGlobal) {
      let charData = [];
      const selectedWidget = WidgetDashBoard.find(
        (e) => e.name === widget.component
      );
      if (selectedWidget && selectedWidget.type) {
        charData = JSON.parse(JSON.stringify(selectedWidget));
      }
      let options = [];
      let units = { is_all: false, selected: [] };

      if (
        widget.use_unit_filters &&
        widget.use_unit_filters.parameters &&
        widget.use_unit_filters.parameters.length
      ) {
        widget.use_unit_filters.parameters.forEach((e) => {
          if (e.name === "date_range_dash" && e.range && !e.temp_range) {
            // console.log(e);
            e.value =
              e.value && e.value.includes(" to ")
                ? e.value
                : this.getCustomRange(e.range);
            instance.$props.range = e.range;
          } else if (e.name === "date_range_dash" && !e.range) {
            // if (e.range !== "Default" && e.temp_range != "Default") {
            //   e.value =
            //     this.getCustomRange(e.temp_range) ||
            //     this.getCustomRange(e.temp_range);
            // }
            e.value = this.getCustomRange("Today");
            instance.$props.range = e.temp_range;
          }
          if (e.name === "date_range_dash" && !e.value) {
            e.value = this.getCustomRange("Today");
          }
        });
        options =
          widget.use_unit_filters.parameters.map((e) => {
            const obj = {};
            obj.field = e.name;
            obj.value = e.value;
            return obj;
          }) || [];

        const unitSource = widget.use_unit_filters.parameters.find(
          (e) => e.name === "unit_source"
        );
        if (options && options.length) {
          options = options.filter((e) => e.name !== "unit_source");
        }

        if (unitSource && unitSource.value && unitSource.value.length) {
          units.selected = unitSource.value;
        }
      }

      if (
        widget.is_unit_lookup &&
        ((widget.units && widget.units.is_all) ||
          (widget.units &&
            widget.units.selected &&
            widget.units.selected.length))
      ) {
        units = widget.units;
      }

      let widgetReq = { widget_type: widget.widget_type };
      if (!widget.is_no_filter) {
        widgetReq = {
          options,
          widget_type: widget.widget_type,
          account_id: widget.account_id
        };
        if (
          widget &&
          widget.use_unit_filters &&
          widget.use_unit_filters.multiple_units
        ) {
          if (isGlobal) {
            const globalFilterReq = { ...this.globalFilters };
            widgetReq.units = {
              is_all: this.globalFilters.isAllUnit,
              selected: globalFilterReq.units
            };

            widgetReq.unit_groups = {
              selected: globalFilterReq.unit_groups
            };
          } else if (Array.isArray(units.selected)) {
            widgetReq.units = units;
          } else if (typeof units.selected === "string") {
            widgetReq.units = {
              is_all: false,
              selected: [units.selected]
            };
          } else {
            widgetReq.units = {
              is_all: true,
              selected: []
            };
          }
        } else {
          widgetReq.unit_id = widget.unit_id;
        }
        if (isGlobal) {
          const globalFilterReq = { ...this.globalFilters };

          widgetReq.unit_groups = {
            selected: globalFilterReq.unit_groups
          };
        } else if (
          widget.is_unit_lookup &&
          ((widget.unit_groups && widget.unit_groups.is_all) ||
            (widget.unit_groups &&
              widget.unit_groups.selected &&
              widget.unit_groups.selected.length))
        ) {
          widgetReq.unit_groups = widget.unit_groups;
        }
      }

      instance.$props.isApiCall = true;
      // componentInstance.$forceUpdate();
      let response = await new ReportService().getWidgetsData(widgetReq);
      instance.$props.isApiCall = false;

      if (response && response.data && response.data.data) {
        let {
          series,
          categories,
          max_level,
          min_level,
          annotations,
          chartOptions,
          labels
        } = response.data.data;
        // const isNoData = response.data.is_no_data;
        instance.$props.isNoData = !series.some((series) => {
          if (Array.isArray(series)) {
            return series.length > 0;
          } else if (
            typeof series === "object" &&
            series !== null &&
            "data" in series
          ) {
            return Array.isArray(series.data) && series.data.length > 0;
          } else {
            return Boolean(series); // To check if the series element is a non-zero number or truthy value
          }
        });
        if (selectedWidget.type === "donut") {
          instance.$props.isNoData = !series.some((series) => {
            if (typeof series === "number") {
              return series > 0;
            }
            return false;
          });
        }
        this.drawWidget(
          {
            min_level,
            max_level,
            series,
            widget,
            charData,
            labels,
            annotations,
            categories,
            chartOptions
          },
          instance
        );
        // widgetData.is_no_data = isNoData;
        // widgetData.is_dummy = isNoData;
      } else if (response && response.error && response.error.message) {
        // this.widgetData.is_no_data = true;
        instance.$props.isNoData = true;
        this.show = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    drawWidget(data, instance, isAdd) {
      try {
        const dd = data;
        let {
          min_level,
          max_level,
          widget,
          series,
          charData,
          labels,
          annotations,
          categories,
          colors,
          chartOptions
        } = data;
        const minValue = Number(min_level) * 0.85;
        const maxValue = Number(max_level) * 1.1;
        this.show = false;
        const gridNode = this.grid.engine.nodes.find(
          (node) => node.id === widget.id
        );
        const colorsData = [];

        if (
          (widget.widget_type === "FuelConsumptionWidget" ||
            widget.type === "FuelConsumptionWidget") &&
          widget.component === "line"
        ) {
          series = series.map((e, index) => {
            if (
              e.config &&
              e.config.is_show_line &&
              e.config.lines &&
              e.config.lines.length
            ) {
              e.config.lines = e.config.lines.map((l, index) => {
                if (!l.color) {
                  l.color = Utils.COLOR[index + 10];
                }
                return l;
              });
            }
            if (e.color) {
              colorsData.push(e.color);
            } else {
              e.color = Utils.COLOR[index];
              colorsData.push(Utils.COLOR[index]);
            }

            return e;
          });
        } else if (
          widget.widget_type !== "FuelConsumptionWidget" &&
          widget.type !== "FuelConsumptionWidget" &&
          widget.component === "line"
        ) {
          charData.series = series;
          charData.chartOptions.xaxis = { categories };
          charData.chartOptions.chart.zoom = {
            enabled: false
          };
          charData.chartOptions.colors = colors;
        }
        if (widget.component === "bar") {
          // const colors = Utils.randomDarkChartColors(series.length);
          charData.series = series;
          charData.chartOptions.xaxis.categories = categories;
          charData.chartOptions.colors = colors;
        } else if (widget.component === "donut") {
          // const colors = generateRandomColors(series.length);
          charData.series = series;
          charData.chartOptions.labels = labels;
          // charData.chartOptions.colors = colors;
        }

        charData.series = JSON.parse(JSON.stringify([]));
        charData.series = JSON.parse(JSON.stringify(series));

        if (widget && widget.component == "table") {
          charData.chartOptions = JSON.parse(JSON.stringify(chartOptions));
          charData.series = JSON.parse(JSON.stringify(series));
        }
        charData.chartOptions.tooltip = {};
        // charData.chartOptions.colors = colorsData;
        if (widget && !widget.is_no_filter && widget.component == "line") {
          if (annotations) {
            charData.chartOptions.annotations = {
              xaxis: annotations.xaxis
            };
          }
          if (widget.widget_type === "FuelConsumptionWidget") {
            charData.chartOptions.yaxis.max = function (max) {
              if (this.zoomed) {
                return max;
              }
              return maxValue;
            };
            charData.chartOptions.tooltip = {
              x: {
                show: true,
                format: "dd MMM yy HH:mm:ss"
              },
              custom: customTooltipFunction
            };
            charData.chartOptions.yaxis.min = function (min) {
              if (this.zoomed) {
                return min;
              }
              return minValue;
            };
          }
        }

        if (widget && widget.component !== "table") {
          // widgetData = charData;
        }

        const me = this;

        try {
          instance.$props.isApiCall = false;
          instance.$props.isNoData =
            charData.series &&
            !charData.series.some((series) => {
              if (Array.isArray(series)) {
                return series.length > 0;
              } else if (
                typeof series === "object" &&
                series !== null &&
                "data" in series
              ) {
                return Array.isArray(series.data) && series.data.length > 0;
              } else {
                return Boolean(series); // To check if the series element is a non-zero number or truthy value
              }
            });
          instance.$props.widget = {
            ...instance.$props.widget,
            series: charData.series,
            chartOptions: charData.chartOptions,
            minValue: minValue,
            maxValue: maxValue
          };

          // this.setChartSize(gridNode.el);
          this.grid.compact();
        } catch (err) {
          // console.log(err);
        }
      } catch (err) {
        // console.log(err);
      }
    },
    async addDashboardWidget(params, isEdit) {
      try {
        this.show = true;

        var widgetId = params.id; // Assuming params.id contains the ID of the widget
        if (!widgetId) {
          this.show = false;
          return;
        }
        let response = await new ReportService().getOneWidget({
          id: widgetId
        });
        this.show = false;
        if (response && response.data) {
          this.updateGlobalFilterArray(response.data);
          let charData = [];
          const selectedWidget = WidgetDashBoard.find(
            (e) => e.name === response.data.component
          );
          if (selectedWidget && selectedWidget.type) {
            charData = selectedWidget;
          }
          if (isEdit) {
            return response.data;
          } else {
            const resData =
              params.widgetsData && params.widgetsData.data
                ? params.widgetsData.data
                : {};
            let maxHeight = -Infinity;
            let maxHeightX = -Infinity;

            for (const node of this.grid.engine.nodes) {
              if (node.y > maxHeight) {
                maxHeight = node.y;
                maxHeightX = node.h;
              } else if (node.y === maxHeight && node.h > maxHeightX) {
                maxHeightX = node.h;
              }
            }

            const widgetData = JSON.parse(JSON.stringify({ ...response.data }));
            widgetData.settings.web.y =
              maxHeightX + maxHeight + 1 === -Infinity
                ? 0
                : maxHeightX + maxHeight + 1;
            this.drawChart(
              {
                ...resData,
                widget: { ...widgetData },
                charData
              },
              null,
              true
            );
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.onClose();
        }
      } catch (err) {
        // console.log(err);
        this.onClose();
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    closeSlider() {
      this.selectedWidget = {};
    },
    removeWidget(data) {
      this.removedWidgetData = { name: data.title, id: data.id };
      this.$bvModal.show("modal-sm-remove");
    },
    updateDateFilter(params, instance) {
      this.getWidgetsData(params, instance);
    },
    onClose(data) {
      this.removedWidgetData = { name: "", id: "" };
      this.$bvModal.hide("modal-sm-remove");
    },
    addGlobalFilter(filters, account_id) {
      this.globalFilters.units = (filters.unit && filters.unit.selected) || [];
      this.globalFilters.unit_groups =
        (filters.group && filters.group.selected) || [];
      this.globalFilters.isAllUnit = filters.isAllUnit === false ? false : true;
      this.globalFilters.account_id = account_id;
      // this.apply(true);
      this.globalWidgets.forEach((widget) => {
        const gridNode = this.grid.engine.nodes.find(
          (node) => node.id === widget.id
        );
        if (gridNode && gridNode.el && gridNode.el.__vue__) {
          const instance = gridNode.el.__vue__;
          this.getWidgetsData(widget, instance, true);
        }
      });
    },
    async removedWidget(params) {
      try {
        this.show = true;
        if (!params || !params.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
        var widgetId = params.id; // Assuming params.id contains the ID of the widget

        // Get the GridStack node associated with the widget ID
        var gridNode = this.grid.engine.nodes.find(
          (node) => node.id === widgetId
        );

        // If the node exists, remove it from the layout

        let response = await new ReportService().deleteWidgets({
          id: params.id
        });
        this.show = false;
        if (response && response.data) {
          this.totalWidgets = this.totalWidgets - 1;
          this.$toast({
            component: ToastificationContent,
            props: {
              text: this.$t("Widget.RemoveSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          if (gridNode) {
            this.grid.removeWidget(gridNode.el);
            this.grid.compact();
          } else {
            console.error("Widget node not found in the GridStack layout.");
          }
          this.onClose();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.onClose();
        }
      } catch (err) {
        this.onClose();
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async updateWidget(widget) {
      const data = await this.addDashboardWidget(widget, true);
      this.selectedWidget = JSON.parse(JSON.stringify(data));
      this.selectedWidgetType = "";

      // this.selectedWidgetType = data.widget_type;
      if (data.widget_type) {
        setTimeout(() => {
          this.$bvModal.show(`modal-sm-consumer-dash-${widget.id}`);
        }, 100);
      }
    },
    dragWidgetClick(me) {
      me.$props.dragEnabled = true;
    }
  },
  beforeDestroy() {
    EventBus.$off("add-dashboard-widget", {});
    EventBus.$off("add-global-filter", {});
  }
};
</script>
<style>
.grid-stack-item .ui-resizable-handle {
  display: block !important;
}
.grid-stack-item {
}
</style>
