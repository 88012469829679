<template>
  <b-card
    :id="`${widget.id}_id`"
    style=""
    class="chart-main"
    :ref="`${widget.id}_id`"
    :style="{
      height: `${
        parseInt(height) ? 'auto' : parseInt(height) + 100
      }px !important`
    }"
  >
    <div style="position: relative; height: 100%">
      <div style="height: 100%">
        <span style="display: none">{{ widget.series }}</span>
        <div
          class="d-flex justify-content-between align-item-center dash-chart-title"
          :class="!widget.is_no_filter ? '' : 'no-date-pick'"
        >
          <div class="widget-title">
            {{ widget.title }}
          </div>

          <div class="d-flex dash-chart-date-filter">
            <div class="">
              <DatePicker
                v-model="dateRange"
                :triggerEvent="triggerEvent"
                :chartType="type"
                :label="''"
                v-if="!widget.is_no_filter && !widget.is_no_date_range_dash"
              />
            </div>

            <div class="drop-dwn">
              <b-dropdown variant="link" toggle-class="p-0" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-left text-body"
                  />
                </template>
                <b-dropdown-item
                  @click="updateWidget(widget)"
                  v-if="!widget.is_no_filter"
                >
                  <div class="d-flex">
                    <div class="main-icon-w">
                      <feather-icon
                        icon="EditIcon"
                        class="text-primary action-icon ml-0"
                        size="18"
                        style="position: relative; cursor: pointer"
                      />
                    </div>
                    <span>{{ dashTranslations.edit || "Edit" }}</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="removeWidget(widget)">
                  <div class="d-flex">
                    <div class="main-icon-w">
                      <feather-icon
                        icon="Trash2Icon"
                        class="text-danger action-icon ml-0"
                        size="18"
                        style="position: relative; cursor: pointer"
                      />
                    </div>
                    <span>{{ dashTranslations.remove || "Remove" }}</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <div class="d-flex curserPointer" @click="onDrag">
                    <div class="main-icon-w">
                      <DragDrop />
                    </div>
                    <span>{{ dashTranslations.move || "Move" }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div
          class="no-options-msg-txt"
          v-if="isNoData && !isApiCall"
          :style="{
            height: `${height}px !important`
          }"
        >
          <h4>{{ dashTranslations.no_dash_message }}</h4>
        </div>
        <div>
          <apexchart
            v-if="
              !isNoData &&
              !isApiCall &&
              widget.component !== 'table' &&
              widget.series &&
              widget.series.length
            "
            :id="widget.id"
            :options="widget.chartOptions"
            :type="widget.type"
            :height="`${parseInt(height) - 100}px `"
            :series="widget.series"
          ></apexchart>
        </div>
        <div v-if="!isNoData && !isApiCall && widget.component == 'table'">
          <div
            class="table-responsive reminders-th"
            :style="{
              height: parseInt(height) - 100 + 'px'
            }"
          >
            <b-table
              striped
              hover
              :items="widget.chartOptions"
              :fields="widget.series"
            >
              <template #cell(unit)="row">
                <b-media class="align-item-center pt-0">
                  <template #aside>
                    <b-avatar size="50" :src="row.item.unit.icon" />
                  </template>
                  <h6 class="mb-0 text-wrap-w">
                    {{ row.item.unit.name }}
                  </h6>
                </b-media>
              </template>
              <template #cell(services)="row">
                <ul class="p-0 mt-0 mb-0 text-wrap-w">
                  <li
                    class="font-500 services"
                    v-for="(service, index) in row.item.services"
                    :key="index"
                  >
                    {{ service }}
                  </li>
                </ul>
              </template>
            </b-table>
          </div>
        </div>
        <br />
        <br />
        <Loader :isHideLogo="true" :show="isApiCall && !isNoData"></Loader>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center widget-drag"
      :style="
        !dragEnabled ? { display: 'none !important' } : { display: 'block' }
      "
      style="
        background-color: blue;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgb(83 83 93 / 11%);
        height: 100%;
      "
    >
      <div class="d-flex main-icon-w">
        <div class="">
          <DragDrop />
        </div>
        <span style="margin-left: 10px">
          {{ dashTranslations.move || "Move" }}</span
        >
      </div>
    </div>
    <!-- <b-skeleton v-if="isApiCall" :height="height" :width="width"></b-skeleton> -->
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import {
  BCard,
  BSkeleton,
  BCardBody,
  BTable,
  BDropdown,
  BDropdownItem,
  BMedia,
  BAvatar
} from "bootstrap-vue";
import Loader from "@/layouts/components/Loader.vue";
import DragDrop from "@/assets/images/icons/drag-drop.svg";
import DatePicker from "./DatePicker.vue";
import validators from "@/utils/validators";
import moment from "moment";

export default {
  name: "Chart",
  props: [
    "type",
    "widget",
    "grid",
    "maxValue",
    "minValue",
    "removeWidget",
    "updateWidget",
    "isApiCall",
    "isNoData",
    "dashTranslations",
    "updateDateFilter",
    "dragWidgetClick",
    "dragEnabled",
    "range"
  ],

  components: {
    apexchart: VueApexCharts,
    BCardBody,
    BTable,
    DragDrop,
    BSkeleton,
    Loader,
    BCard,
    BDropdown,
    BDropdownItem,
    DatePicker,
    vSelect,
    BMedia,
    BAvatar
  },

  data: function () {
    return {
      widgetReq: {},
      height: "300",
      rangeValue: "default",
      width: "300",
      defaultDate: "DefaultDate",
      dragCard: "",
      date: "",
      triggerEvent: false,
      ranges: this.getCustomRanges1(true),
      dateRange: this.getCustomRange("Today"),
      seriesWidth: window.innerWidth <= 667 ? 40 : 100,
      isMounted: true
    };
  },

  computed: {},
  mounted() {
    const me = this;

    if (this.range) {
      this.triggerEvent = !this.triggerEvent;
      this.dateRange = this.getCustomRange(this.range);
    }
    setTimeout(() => {
      this.isMounted = false;
    }, 3000);
  },
  watch: {
    range(val) {
      if (val) {
        this.dateRange = this.getCustomRange(val);
      }
    },
    isApiCall(val) {
      if (!val) {
        if (
          this.widget &&
          this.widget.chartOptions &&
          this.widget.chartOptions.chart
        ) {
          this.triggerEvent = !this.triggerEvent;
          this.widget.chartOptions.chart.events = {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
            beforeZoom: this.handleZoomedEvent,
            beforeResetZoom: this.handleZoomResetEvent,
            zoomed: this.handleZoomedEvent,
            updated: this.handleUpdateEvent
          };
        }
      }
    },
    dateRange: {
      handler(newValue, oldValue) {
        try {
          if (
            newValue &&
            this.widget &&
            this.widget.use_unit_filters &&
            this.widget.use_unit_filters.parameters &&
            this.widget.use_unit_filters.parameters.length &&
            !this.isMounted
          ) {
            this.widget.use_unit_filters.parameters.forEach((element) => {
              if (element.name == "date_range_dash") {
                element.value = newValue;
                // console.log(newValue);
                this.updateDateFilter(this.widget, this);
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      },
      deep: true
    }
  },
  methods: {
    updateHeightWidth(height, width) {
      this.height = height;
      this.width = width;
      this.triggerEvent = !this.triggerEvent;
    },
    getRange() {
      this.ranges = validators.getCustomRanges1(true);
      // this.ranges.push({
      //   key: "default",
      //   label: "Default",
      //   value: ""
      // });
    },

    triggerChildEvent() {
      // Toggle the event trigger
    },
    updateWidgetData() {
      this.updateWidget(JSON.parse(JSON.stringify(this.widget)));
    },
    handleZoomedEvent(chartContext, opt) {
      if (!opt.yaxis || !opt.yaxis) {
        opt.yaxis = chartContext.w.config.yaxis;
      }
      chartContext.w.config.series = this.widget.series;
      opt.yaxis.forEach((axis) => {
        axis.zoomed = true;

        const { max, min } = this.findMaxMinInRange(
          chartContext.w.config.series,
          opt.xaxis.min,
          opt.xaxis.max
        );
        if (max !== -Infinity) {
          axis.max = function () {
            return max * 1.1;
          };
        } else {
          const me = this;
          axis.max = function () {
            return me.widget.maxValue;
          };
        }
        if (min !== Infinity) {
          axis.min = function () {
            return min * 0.9;
          };
        } else {
          const me = this;
          axis.min = function () {
            return me.widget.minValue;
          };
        }
      });
      // Update the chart with new options
    },
    handleZoomResetEvent(chartContext, opt) {
      // Update y-axis max value to the zoomed max value
      opt.config.yaxis.forEach((axis) => {
        axis.zoomed = false;
      });
      this.handleZoomedEvent(chartContext, {
        yaxis: opt.config.yaxis,
        xaxis: opt.config.xaxis
      });
    },
    onDrag() {
      this.dragCard = this.widget.id;
      this.dragWidgetClick(this);
      this.triggerEvent = !this.triggerEvent;
    },
    handleUpdateEvent(chartContext, opt) {
      // Update y-axis max value to the zoomed max value
      chartContext.w.config.series = this.widget.series;
      // if (this.apiCall) {
      //   this.apiCall = false;
      //   opt.config.yaxis.forEach((axis) => {
      //     axis.zoomed = false;
      //   });

      //   this.handleZoomedEvent(chartContext, {
      //     yaxis: opt.config.yaxis,
      //     xaxis: opt.config.xaxis
      //   });
      //   // this.widgetData.Options.chartOptions.yaxis.max = this.maxValue;
      //   // this.widgetData.Options.chartOptions.yaxis.min = this.minValue;
      // }
    },
    findMaxMinInRange(dataArray, startTimestamp, endTimestamp) {
      let maxValue = -Infinity;
      let minValue = Infinity;

      for (const dataSet of dataArray) {
        for (const [timestamp, value] of dataSet.data) {
          if (timestamp >= startTimestamp && timestamp <= endTimestamp) {
            maxValue = Math.max(maxValue, value);
            minValue = Math.min(minValue, value);
          }
        }
      }

      return { max: maxValue, min: minValue };
    }
  }
};
</script>
<style>
.chart-main {
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.widget-drag {
  cursor: move;
}
.widget-drag:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.widget-title {
  font-family: Inter;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  text-align: left;
  color: #16161d;
}
</style>
<style lang="scss">
.dropdown-menu .show {
  transform: translate3d(40px, 19px, 0px);
}
.chart-main {
  background-color: transparent !important;
  box-shadow: 0px 0px 0px 0px rgba(34, 41, 47, 0.1) !important;
  padding: 0px 16px 16px 0px !important;
  .card-body {
    background-color: white !important;
    border-radius: 6px;
  }
}
.main-icon-w {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
}
</style>

<style lang="scss">
.table-responsive {
  overflow-y: auto;
}
.no-options-msg-txt {
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
}
@media only screen and (max-width: 667px) {
  .dash-chart-title {
    flex-flow: wrap !important;
    .dash-chart-date-filter {
      margin-top: 20px;
    }
    .dash-chart-date-filter {
      margin-top: 20px;
      justify-content: space-between;
      width: 100%;
    }
  }
  .chart-main {
    padding: 0px 0px 16px 0px !important;
  }
  .no-date-pick {
    display: flex;
    align-items: center;
    &.dash-chart-title .dash-chart-date-filter {
      width: auto;
      margin-top: 0px;
    }
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.dash-chart-date-filter .v-select {
  min-width: 150px;
}
.text-wrap-w {
  text-wrap: nowrap;
}
.reminders-th th {
  text-align: right !important;
}
</style>
