<template>
  <div>
    <div class="dash-widget-main">
      <div
        class="customizer-section auto-search-padding-0"
        style="margin: 0px 6px"
        :class="isEdit ? 'disabled' : ''"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.CONSUMER_DASH_SUB_ACC,
            subject: constants.PERMISSIONS_MODEL.WIDGETS
          }) &&
          !widget.is_no_filter &&
          !widget.disabled_account_units
        "
      >
        <AccountSearch
          v-model="accountId"
          perfectScrollbarSettings
          :placeholderName="$t('device.AllocatedAccount')"
          :accountsList="[]"
        />
      </div>

      <div
        v-if="
          widget &&
          !widget.use_unit_filters.multiple_units &&
          !widget.is_no_filter &&
          !widget.disabled_account_units
        "
        style="margin: 0px 13px -10px 15px"
        :class="isEdit ? 'disabled' : ''"
      >
        <UnitSearch
          class="unit-search-v2 v-2-unit wh-unit-h"
          v-model="selectedUnit"
          :accountId="accountId"
          componentKey="dash_widgets"
          selectedMenu=""
        />
      </div>
      <span style="display: none">{{ widget }}</span>
      <parameter-components
        v-if="
          widget &&
          !widget.is_no_filter &&
          widget.use_unit_filters &&
          widget.use_unit_filters.parameters &&
          ((selectedUnit && !widget.use_unit_filters.multiple_units) ||
            widget.use_unit_filters.multiple_units)
        "
        :unit_id="selectedUnit"
        :is_multiple_unit="
          widget &&
          widget.use_unit_filters &&
          widget.use_unit_filters.multiple_units
        "
        :account_id="accountId"
        :parameters="
          widget &&
          widget.use_unit_filters &&
          widget.use_unit_filters.parameters
        "
        v-model="widget.use_unit_filters.parameters"
        :isEditable="!isEditable"
        :isGlobalFilter="isGlobalFilter"
        @checkValidate="isInValid()"
      />
      <div
        class="customizer-section unit-search-comp-ui"
        :class="isGlobalFilter ? 'disabled' : ''"
        v-if="
          widget &&
          widget.use_unit_filters.multiple_units &&
          widget.is_unit_lookup &&
          !this.isGlobalFilter
        "
      >
        <NotificationUnitsSelection
          :isEditable="true"
          :selectedUnitsData="selectedUnitsData"
          :accountId="accountId"
          :selectedUnitByUser="selectedUnitByUser"
          :isClear="isClear"
          :unitFun="unitFun"
          :componentKey="'dashboard'"
          :getUnitLookupCount="getUnitLookupCount"
          :totalLookUpCount="totalLookUpCount"
          :isAllUnitEnabled="isAllUnitEnabled"
          :removedSelectedItem="removedSelectedItem"
        ></NotificationUnitsSelection>
        <!-- <DashboardUnitFilter :setFilter="setFilter"  :accountId="accountId"></DashboardUnitFilter> -->
      </div>
    </div>
    <!-- </vue-perfect-scrollbar> -->
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BLink,
  BButton,
  BImg,
  BCardText,
  BTabs,
  BTab,
  VBTooltip
} from "bootstrap-vue";

import AccountSearch from "@/views/Unit/Unit-V2/AccountSearch.vue";
import ParameterComponents from "@/views/Reports/parameterComponent/ParameterComponents.vue";
import EventBus from "@/EventBus";
import NotificationUnitsSelection from "@/views/Notification/NotificationUnitsSelection.vue";
import slidersIcon from "@/assets/images/icons/slidersIcon.svg";
import UnitSearch from "@/views/Unit/UnitTracking/shared/UnitSearch.vue";
export default {
  components: {
    // BSV
    AccountSearch,
    BFormRadioGroup,
    VBTooltip,
    BFormGroup,
    UnitSearch,
    BLink,
    // DashboardUnitFilter,
    BButton,
    NotificationUnitsSelection,
    BImg,
    slidersIcon,
    BCardText,
    BTabs,
    BTab,
    ParameterComponents
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      showModal: false,
      accountId: localStorage.getItem("USER_ACCOUNT_ID"),
      isEditable: true,
      selectedUnit: "",
      selectedUnitsData: [],
      inValidApply: true,
      applyInterval: null,
      totalLookUpCount: 0,
      is_refresh_enable: false,
      removedSelectedItem: {},
      selectedUnselectedItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] },
        isAllUnit: true
      },
      selectedUnselectedFinalItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] }
      },
      selectedUnitByUser: [],
      isClear: false,
      notification: {
        units: {}
      },
      isApplyDisabledFlag: false,
      dashboard: {
        parameters: [],
        account_id: localStorage.getItem("USER_ACCOUNT_ID") || "",
        id: null
      }
    };
  },
  watch: {
    accountId() {
      this.$emit("filterChanges", true);
      this.setApplyInterVal();
    },
    selectedUnit() {
      this.$emit("filterChanges", true);
      this.setApplyInterVal();
    },
    isGlobalFilter(val) {
      if (val) {
        this.clearApplyInterval();
        this.inValidApply = false;
      } else {
        this.setApplyInterVal();
      }
    },
    widget: {
      deep: true,
      handler(newOptions) {
        this.dashFilter();
        this.setApplyInterVal();
      }
    }
  },
  created() {
    EventBus.$on("check-is-have-unit-or-group", this.isApplyDisabled);
  },
  props: ["widget", "globalFilters", "isGlobalFilter", "isEdit"],
  mounted() {
    if (this.widget && this.widget.unit_id) {
      this.selectedUnit = this.widget.unit_id;
      this.setApplyInterVal();
    }
  },
  methods: {
    isAllUnitEnabled(e) {},
    isApplyDisabled(flag) {
      this.isApplyDisabledFlag = flag;
      this.setApplyInterVal();
    },
    setApplyInterVal() {
      const me = this;
      this.applyInterval = setInterval(() => {
        me.dashFilter();
        me.clearApplyInterval();
      }, 200);
    },
    clearApplyInterval() {
      if (this.inValidApply || this.isGlobalFilter) {
        clearInterval(this.applyInterval);
      }
    },
    apply() {
      this.is_refresh_enable = true;
    },
    dashFilter() {
      this.inValidApply = this.isInValid();
      if (this.widget && !this.accountId && this.widget.account_id) {
        this.accountId = this.widget.account_id;
      }
      if (this.widget && this.widget.unit_id) {
        this.selectedUnit = this.widget.unit_id;
      }
      const obj = {
        account_id: this.accountId,
        unit_id: this.selectedUnit,
        parameters: this.widget.use_unit_filters.parameters,
        is_in_valid: this.inValidApply,
        units: this.widget.units,
        unit_groups: this.widget.unit_groups
      };
      this.$emit("dashboardChatData", obj);
    },
    isInValid() {
      const { widget, accountId } = this;
      if (this.isGlobalFilter) {
        return false;
      } else if (widget.is_unit_lookup) {
        const unitsList = this.selectedUnitsData.filter((e) => e.selected);
        if (!unitsList.length && !this.widget.units.is_all) {
          return true;
        } else if (unitsList.length && !this.widget.units.is_all) {
          return false;
        } else if (unitsList.length && this.widget.units.is_all) {
          return false;
        } else if (!unitsList.length && this.widget.units.is_all) {
          return false;
        }
      } else {
        return (
          !accountId ||
          !widget ||
          !widget.use_unit_filters ||
          !(widget.use_unit_filters.multiple_units || this.selectedUnit) ||
          !widget.use_unit_filters.parameters ||
          widget.use_unit_filters.parameters.some(
            (e) => e.required && (!e.value || !e.value.length)
          )
        );
      }
    },
    setFilter(filter) {
      this.filters = filter;
    },
    unitFun(data, isAlreadySave, flag) {
      // const { group, unit } = this.selectedUnselectedItems;
      // const { selected: selectedGroup, unselected: unselectedGroup } = group;
      // const { selected: selectedUnit, unselected: unselectedUnit } = unit;
      // const pushToSelected = (element, type) => {
      //   if (!this.selectedUnselectedItems[type].selected.includes(element.id)) {
      //     if (!element.is_selected && !isAlreadySave) {
      //       this.selectedUnselectedFinalItems[type].selected.push(element.id);
      //     }
      //     this.selectedUnselectedItems[type].selected.push(element.id);
      //   }
      // };
      // const pushToUnselected = (element, type) => {
      //   if (
      //     !this.selectedUnselectedItems[type].unselected.includes(element.id)
      //   ) {
      //     this.selectedUnselectedItems[type].unselected.push(element.id);
      //   }
      //   if (this.selectedUnselectedItems[type].selected.includes(element.id)) {
      //     this.selectedUnselectedItems[type].selected =
      //       this.selectedUnselectedItems[type].selected.filter(
      //         (e) => e.id === element.id
      //       );
      //   }
      // };
      const selectedUnit = [];
      const selectedGroup = [];
      data.selected.forEach((element) => {
        if (element && element.source === "GROUP") {
          if (!selectedGroup.includes(element.id)) {
            selectedGroup.push(element.id);
          }
        } else if (element && element.source === "UNIT") {
          if (!selectedUnit.includes(element.id)) {
            selectedUnit.push(element.id);
          }
        }
      });

      // this.notification.units = [
      //   { type: "GROUP", items: group },
      //   { type: "UNIT", items: unit }
      // ];

      this.widget.unit_groups = { selected: selectedGroup, is_all: false };
      this.widget.units = {
        selected: selectedUnit,
        is_all: flag
      };

      this.selectedUnitsData = data.selected;
      this.isInValid();
      this.dashFilter();
    },
    getUnitLookupCount(count) {
      this.totalLookUpCount = count;
    }
  },

  beforeDestroy() {
    this.accountId = "";
    this.selectedUnit = "";
    const { widget } = this;
    if (
      widget.use_unit_filters &&
      widget.use_unit_filters.parameters &&
      widget.use_unit_filters.parameters.length
    ) {
      this.widget.use_unit_filters.parameters =
        this.widget.use_unit_filters.parameters.map((e) => {
          e.value = "";
          return e;
        });
    }
    EventBus.$off("check-is-have-unit-or-group", this.isApplyDisabled);
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.customizer-section {
  padding: 0.5rem;
  // border-bottom: 1px solid #ebe9f1;
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ps-customizer-area {
  height: calc(100% - 110px);
}
</style>
<style lang="scss">
.filter-tab .nav-item {
  width: 50%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
}
.filter-tab .nav-item .active {
  width: 100%;
  font-weight: 800;
  border-bottom: 2px solid var(--primary);
}
.filter-tab #__BVID__164__BV_tab_controls_ {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 20px;
}
.filter-tab #__BVID__164__BV_tab_container_ {
  width: 100% !important;
}
.widgets {
  width: 46% !important;
  height: 20vh;
  background: #f6f6f6;
  border: 1px #e2e2e2 solid;
  margin: 2%;
  cursor: pointer;
}
// .main-filter-tab {
//   margin-left: 5px;
//   margin-right: 5px;
//   width: 100%;
//   height: 100vh;
//   overflow-y: auto;
//   display: flex;
//   justify-content: start;
//   flex-wrap: wrap;
//   height: 92vh;
//   overflow-x: hidden;
// }
.widgets-icon {
  max-height: 80%;
  min-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widgets-name {
  color: #16161d;
  font-size: 13px;
  font-weight: 800;
  line-height: 12px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: 20%;
  max-height: 20%;
}

.dark-layout .widgets span {
  color: #d0d2d6;
}
.dark-layout .widgets {
  background-color: #161d30;
  border: 1px #3f3f3f solid;
}
.unit-filter-dashboard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
#__BVID__185___BV_modal_outer_ {
  z-index: 9999 !important;
}
.unit-filter-tab {
  width: 30%;
  height: 50vh;
}
.graph-tab {
  width: 70%;
  height: 80vh;

  background-color: #16161d;
}
.main-popup {
  display: flex;
}
.search-outer {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  .search-filter {
    width: 100%;
    .pr-1 {
      display: none;
    }
    .form-group {
      width: 100%;
    }
  }
}
.dash-widget-title {
  margin: 1rem 6px !important;
}
.dash-widget-main {
  // max-height: calc(100vh - 380px);
  // min-height: calc(100vh - 380px);
  height: 500px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  .dash-widget-main {
    max-height: calc(100vh - 67vh) !important;
    min-height: calc(100vh - 67vh) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .dash-parent {
    margin-top: 10px !important;
  }
  .close {
    margin-right: -2px !important;
  }
  #modal-sm-consumer-dash___BV_modal_body_ {
    overflow-x: hidden !important;
  }
}
</style>
