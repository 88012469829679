<template>
  <b-form-group
    :label="`${label ? (is_required || required ? label + '*' : label) : ''}`"
    class="dash-cal-1 dash-home-calender dash-board-dynamic-postion"
  >
    <div
      ref="myInput"
      style="
        float: right;
        color: transparent;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 1px;
      "
    >
      a
    </div>

    <date-range-picker
      v-model="dateRange"
      @update="updateValues"
      :auto-apply="true"
      :opens="
        screen === 'mobile'
          ? 'right'
          : screen === 'tab'
          ? 'left'
          : chartType === 'line'
          ? 'right'
          : datePickerPosition
      "
      :maxDate="getCurrentTime()"
      :disabled="isEditable"
      :ranges="customRanges"
    >
      <template #input="picker">
        <span v-if="dateRange && dateRange.startDate">
          {{
            `${picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""}`
          }}
          {{
            `${picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "}`
          }}
        </span>
      </template>
      <!--    date slot-->
      <template #date="data">
        <span class="small">{{ data.date | dateCell }}</span>
      </template>
      <!--    ranges (new slot syntax) -->
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="ranges.clickRange(range), getRange(name)"
            >
              {{ name }}
              <!-- <b>{{ $t(`dateRange.${name}`) }}</b> -->
            </li>
          </ul>
        </div>
      </template>
    </date-range-picker>
  </b-form-group>
</template>

<script>
import constants from "@/utils/constants";
import { BFormGroup } from "bootstrap-vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import moment from "moment";

export default {
  name: "SlotsDemo",
  components: { DateRangePicker, BFormGroup },
  data() {
    return {
      datePickerPosition: "center",
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      },
      customRanges: this.getCustomRanges(true),
      screen: this.getScreen()
    };
  },
  mounted() {
    this.getScreen();
    this.$nextTick(() => {
      this.getInputPosition();
    });

    window.addEventListener("resize", () => {
      this.getInputPosition();
    });
    if (this.value && typeof this.value == "string") {
      const dates = this.value.split(" to ");

      this.dateRange.startDate = dates[0].trim();
      // this.dateRange.startDate = this.dateRange.startDate.toLocaleString();
      this.dateRange.endDate = dates[1].trim();
      this.parameterChanged(this.dateRange);
      // this.dateRange.endDate = this.dateRange.endDate.toLocaleString();
    } else if (this.value && this.value.startDate && this.value.endDate) {
      this.dateRange.startDate = this.value.startDate;
      // this.dateRange.startDate = this.dateRange.startDate.toLocaleString();
      this.dateRange.endDate = this.value.endDate;
      this.parameterChanged(this.value);
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getInputPosition);
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  // props: ["selectedMenu", "unit_id"],
  props: [
    "name",
    "desc",
    "label",
    "type",
    "component",
    "parameterDataChanged",
    "value",
    "isEditable",
    "component_key",
    "options",
    "is_required",
    "required",
    "is_custom",
    "triggerEvent",
    "chartType"
  ],
  watch: {
    value: {
      deep: true,
      handler(newOptions) {
        if (this.value && typeof this.value == "string") {
          const dates = this.value.split(" to ");

          this.dateRange.startDate = dates[0].trim();

          this.dateRange.endDate = dates[1].trim();
          this.parameterChanged(this.dateRange);
        } else if (this.value && this.value.startDate && this.value.endDate) {
          this.dateRange.startDate = new Date(this.value.startDate);

          this.dateRange.endDate = new Date(this.value.endDate);
          this.parameterChanged(this.value);
        } else {
          this.parameterChanged(this.dateRange);
        }
      }
    },
    "$store.state.verticalMenu.isVerticalMenuCollapsed"() {
      this.getInputPosition();
    },
    triggerEvent(newVal) {
      if (newVal) {
        this.getInputPosition();
      }
    }
  },
  methods: {
    getScreen() {
      if (window.innerWidth <= 991 && window.innerWidth > 512) {
        this.screen = "tab";
      } else if (window.innerWidth <= 512) {
        this.screen = "mobile";
      } else {
        this.screen = "desktop";
      }
      return this.screen;
    },
    getInputPosition() {
      setTimeout(() => {
        let xPosition = 0;
        let element = this.$refs.myInput;
        while (element) {
          xPosition += element.offsetLeft + element.clientLeft;
          element = element.offsetParent;
        }
        const viewportWidth = window.innerWidth;
        // console.log(element.innerWidth);
        if (xPosition < viewportWidth / 2) {
          this.datePickerPosition = "left";
          // console.log(
          //   "Element is positioned on the left side of the viewport."
          // );
        } else {
          this.datePickerPosition = "right";
          // console.log(
          //   "Element is positioned on the right side of the viewport."
          // );
        }
      }, 1000);
    },

    getRange(range) {
      this.$emit("emitDataToParent", range);
    },
    updateValues(e) {
      // if (
      //   new Date().getTime() < e.endDate.getTime() ||
      //   new Date().getTime() < e.startDate.getTime() ||
      //   e.startDate.getTime() > e.endDate.getTime()
      // ) {
      //   console.log("error");
      // }
      this.parameterChanged(e);
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTimeWithoutTimezone(
        date,
        format
          ? format
          : this.component_key === "dash_widget"
          ? constants.DATE_TIME_FORMAT
          : constants.DATE_FORMAT
      );
    },

    parameterChanged() {
      if (this.component_key !== "dash_widget" && this.dateRange.startDate) {
        const startDate = moment(this.dateRange.startDate);
        startDate.hours(0);
        startDate.minutes(0);
        startDate.seconds(0);
        this.dateRange.startDate = startDate;
      }

      this.dateRange.startDate = this.convertDatePerTimezone(
        this.dateRange.startDate
      );

      if (this.dateRange.endDate) {
        let endDate = moment(this.dateRange.endDate);
        const now = moment();

        const isToday = endDate.isSame(now.format(), "day");
        const isThisWeek = endDate.isSame(now.format(), "week");
        const isThisMonth = endDate.isSame(now.format(), "month");

        const yesterday = moment().subtract(1, "day").startOf("day");
        const isYesterday = endDate.isSame(yesterday, "day");

        const lastWeekStart = moment().subtract(1, "week").startOf("week");
        const lastWeekEnd = moment().subtract(1, "week").endOf("week");
        const isLastWeek = endDate.isBetween(
          lastWeekStart,
          lastWeekEnd,
          null,
          "[]"
        );

        const lastMonthStart = moment().subtract(1, "month").startOf("month");
        const lastMonthEnd = moment().subtract(1, "month").endOf("month");
        const isLastMonth = endDate.isBetween(
          lastMonthStart,
          lastMonthEnd,
          null,
          "[]"
        );

        if (isToday || isThisWeek || isThisMonth) {
          // Set current time for Today, This Week, This Month
          endDate.hours(now.hours());
          endDate.minutes(now.minutes());
          endDate.seconds(now.seconds());
        }

        if (isYesterday || isLastWeek || isLastMonth) {
          // Set to 23:59:59 for Yesterday, Last Week, Last Month
          endDate.hours(23);
          endDate.minutes(59);
          endDate.seconds(59);
        }

        this.dateRange.endDate = endDate;
      }

      this.dateRange.endDate = this.convertDatePerTimezone(
        this.dateRange.endDate
      );
      this.$emit(
        "input",
        `${this.dateRange.startDate} to ${this.dateRange.endDate}`
      );
    }
  }
};
</script>

<style lang="scss">
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dash-cal-1 {
  .daterangepicker.opensright {
    left: auto !important;
    right: auto !important;
  }
}
.dark-layout {
  .dash-cal-1 {
    .reportrange-text {
      border: 1px solid #404656 !important;
    }
  }
}
.dash-cal-1 {
  .reportrange-text {
    border: 1px solid #d8d6de !important;
  }
}
.text-black {
  color: #000;
}

.fixed-position-date-picker .daterangepicker {
  position: fixed;
}
.dark-layout {
  .daterangepicker .calendar-table .next span,
  .daterangepicker .calendar-table .prev span {
    border: solid #d0c9c9 !important;
    border-width: 0 2px 2px 0 !important;
  }
}
@media only screen and (max-width: 991px) {
  .fixed-position-date-picker .daterangepicker {
    width: 280px;
    .drp-calendar.right {
      padding: 8px 0 8px 8px;
    }
  }
  .date-report {
    .daterangepicker.opensleft {
      right: 2vh;
    }
  }
}
.dash-cal-1 {
  select.form-control:not([multiple="multiple"]) {
    background-image: none;
  }
  select.hourselect {
    padding: 9px 9px 9px 14px !important;
  }

  select.minuteselect {
    padding: 9px 9px 9px 14px !important;
  }
}
.dark-layout {
  .dash-cal-1 {
    select.form-control:not([multiple="multiple"]) {
      background-image: none;
    }
    select.hourselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }

    select.minuteselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }
  }
}
</style>
<style lang="scss">
// @media screen and (min-width: 991px) {
//     .daterangepicker.show-ranges.show-weeknumbers, .daterangepicker.show-ranges {
//         min-width: none;
//     }
// }
@media only screen and (min-width: 992px) {
  .dash-cal-1.dash-board-dynamic-postion .daterangepicker {
    &.opensleft {
      right: auto !important;
      left: -110px !important;
      z-index: 999;
    }
    &.opensright:before {
      right: 130px !important;
      left: auto !important;
    }
    &.opensright:after {
      right: 130px !important;
      left: auto !important;
    }
    &.opensleft:before {
      left: 140px !important;
      right: auto !important;
    }
    &.opensleft:after {
      left: 140px !important;
      right: auto !important;
    }
    &.openscenter {
      right: auto;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 999;
    }

    &.opensright {
      left: auto !important;
      right: 0px !important;
      z-index: 999;
    }
  }
  .daterangepicker {
    &.show-ranges.show-weeknumbers,
    &.show-ranges {
      min-width: 685px !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .daterangepicker.show-ranges.show-weeknumbers,
  .daterangepicker.show-ranges {
    min-width: auto !important;
  }
  .daterangepicker .calendars-container {
    flex-wrap: wrap !important;
  }
  .daterangepicker.show-ranges .drp-calendar.right {
    // border-left: 1px solid !important;
  }
}
.dash-home-calender {
  .reportrange-text {
    display: flex;
    align-items: center;
    padding: 10px !important;
    white-space: nowrap;
  }
}
</style>
