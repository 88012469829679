<template>
  <b-modal
    id="supported-unit-list"
    class="custom-size-modal"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    :hide-footer="hideFooter"
  >
    <div class="d-flex justify-content-center">
      <b-card-title title-tag="h2" class="title font-weight-bold mb-0">
        <div style="text-transform: capitalize">
          {{ $t("Notification.Units") }} {{ source }}
        </div>
      </b-card-title>
    </div>
    <div>
      <b-table
        :fields="fields"
        :items="items"
        striped
        responsive
        style="border-radius: 5px"
        class="mt-1"
      >
        <template #cell(name)="row">
          <div class="d-flex align-items-center">
            <b-avatar
              :variant="row.item.variant"
              :text="row.item.avtar_txt"
              :src="row.item.icon"
            />
            <strong class="padding-l-10">{{ row.item.name }}</strong>
          </div>
        </template>
        <template #cell(action)="row">
          <b-badge
            pill
            class="font-light"
            variant="light-primary"
            v-for="(item, index) in row.item.channel"
            :key="index"
          >
            {{ item }}
          </b-badge>
        </template>
      </b-table>
      <hr />
      <div class="demo-spacing-0 d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
      <hr />
      <div class="demo-spacing-0 d-flex justify-content-center">
        <b-button variant="outline-secondary" @click="close" class="">
          Close
        </b-button>
      </div>
    </div>
    <Loader :show="show" />
  </b-modal>
</template>

<script>
import {
  BRow,
  BCard,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BPagination,
  BCardTitle,
  BBadge,
  BFormInput,
  BFormGroup
} from "bootstrap-vue";
import UnitService from "@/libs/api/unit-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BCard,
    BAvatar,
    BPagination,
    BCardTitle,
    BBadge,
    BFormInput,
    Loader,
    BFormGroup
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          tdClass: "width",
          thClass: "text-left"
        },
        {
          key: "action",
          label: "",
          thClass: "text-right",
          tdClass: " text-right no-wrap"
        }
      ],
      items: [],
      totalDealer: 0,
      show: false,
      user: {},
      perPage: 10,
      currentPage: 1,
      hideFooter: true
    };
  },
  props: ["alertId", "groupId", "source"],
  watch: {
    groupId(val) {
      this.getGroupUnits();
    },
    source(val) {
      this.getGroupUnits();
    }
  },
  mounted() {
    this.getGroupUnits();
  },
  methods: {
    async getGroupUnits() {
      try {
        if (!this.alertId || !this.groupId) {
          return false;
        }
        this.show = true;
        const me = this;
        if (!this.filter || !this.filter.value) {
          this.filter = {};
        }
        let requestData = {
          page: this.currentPage,
          page_size: parseInt(this.perPage),
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : [],
          alert_id: this.alertId,
          group_id: this.groupId,
          source: this.source
        };

        let response = await new UnitService().getGroupUnits(requestData);

        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            return u;
          });
          this.totalNotification =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    close() {
      this.$bvModal.hide("supported-unit-list");
    }
  }
};
</script>
<style lang="scss">
.font-light {
  margin-right: 10px;
  padding: 5px 15px;
}
.no-wrap {
  white-space: nowrap;
}
.ico-check {
  position: relative;
  .second-check {
    position: absolute;
    top: 2px;
    left: 3px;
  }
}
</style>
