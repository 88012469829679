export default {
  type: "donut",
  name: "donut",
  series: [0, 0, 0, 0, 0, 0],
  chartOptions: {
    chart: { type: "donut" },
    legend: {
      show: true,
      position: "right",
      fontSize: "12px",
      fontFamily: "Montserrat",
      horizontalAlign: "center",
      width: 200,
      itemMargin: {
        vertical: 10
      },
      containerMargin: {
        left: 43,
        top: 6
      }
    },
    colors: ["#73e63b", "#b31eee", "#1e7d45", "#c36ec6", "#e80808", "#d8268b"],
    dataLabels: {
      enabled: true
    },
    plotOptions: {
      pie: {
        donut: {
          size: 0
        }
      }
    },
    labels: [
      "Concox-JM01",
      "Polygon Israel-Polygon Model",
      "Queclink-GL50B Lite",
      "Queclink-CV100LG Dual Dash Camera",
      "Concox-LG301",
      "Teltonika-Teltonika"
    ],
    responsive: [
      {
        breakpoint: 800,
        options: {
          pie: {
            donut: {
              size: 3
            }
          },
          legend: {
            position: "bottom",
            width: "100%",
            height: 120,
            itemMargin: {
              vertical: 7
            }
          }
        }
      }
    ]
  }
};
