<template>
  <div class="dash-parent mt-0">
    <div style="display: none">{{ widget && widget.is_dummy }}</div>

    <div v-if="!isDummy">
      <vue-apex-charts
        ref="chart"
        type="line"
        :options="option.chartOptions"
        :series="option.series"
      />
    </div>
    <b-overlay
      v-if="isDummy"
      :show="true"
      variant="transparent"
      :opacity="1"
      blur="2px"
      class="height-over disabled"
    >
      <template #overlay><div></div></template>
      <div>
        <span v-if="isNoData" class="empty-text-child">{{
          $t("NODashboardDataMessage")
        }}</span>
        <span v-if="!isNoData" class="empty-text-child-1">{{
          $t("dashboard.NoData")
        }}</span>
        <vue-apex-charts
          v-if="isDummy"
          type="line"
          :options="chartOptions"
          :series="series"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { VBTooltip, BCard, BSkeletonImg, BOverlay } from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loader from "@/layouts/components/Loader.vue";
import constants from "@/utils/constants";
import ChartjsComponentLineChart from "./ChartjsComponentLineChart.vue";
import FuelUsageWidget from "@/libs/dashboard/FuelUsageWidget";
export default {
  components: {
    VueApexCharts,
    BCard,
    ChartjsComponentLineChart,
    BSkeletonImg,
    BOverlay,
    Loader
  },
  data() {
    return {
      p: {},
      isNoData: false,
      isDummy: true,
      chartOptions: FuelUsageWidget.Options.chartOptions,
      series: [
        {
          name: "Fuel Level",
          type: "line",
          config: {
            is_show_line: true,
            is_custom_tool: false,
            options: [],
            lines: []
          },
          data: [
            [1710720480000, 10],
            [1710721080000, 20],
            [1710721680000, 30],
            [1710722280000, 32],
            [1710722880000, 43]
          ]
        }
      ],
      option: {
        chartOptions: JSON.parse(
          JSON.stringify(FuelUsageWidget.Options.chartOptions)
        ),
        series: JSON.parse(JSON.stringify(FuelUsageWidget.Options.series))
      },
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20;
            };
            /* eslint-enable */
          }
        }
      ],
      show: false
    };
  },

  directives: {
    "b-tooltip": VBTooltip
  },
  props: ["widget"],
  watch: {
    widget: {
      deep: true,
      handler(val) {
        if (val && val.component && val.Options) {
          this.isDummy = val.is_dummy;
          this.$nextTick(() => {
            this.option.chartOptions = val.Options.chartOptions;
            this.option.chartOptions.colors = val.Options.chartOptions.colors;
            this.option.chartOptions.annotations =
              val.Options.chartOptions.annotations;
            this.option.series = JSON.parse(JSON.stringify(val.Options.series));
            if (this.$refs && this.$refs.chart) {
              // this.$refs.chart.appendSeries(
              //   JSON.parse(JSON.stringify(val.Options.series))
              // );
              // this.$refs.chart.updateOptions(this.option);
            }

            this.isNoData = val.is_no_data;
          });
        } else {
          this.option.series = [];
          //   this.option.chartOptions = FuelUsageWidget.Options.chartOptions;
          this.option.series = JSON.parse(JSON.stringify(this.series));
        }
      }
    }
  },
  async mounted(val) {},
  methods: {}
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.dash-parent {
  max-width: 100%;
  margin-top: 0px !important;

  // max-height: 50vh;
}
@media only screen and (max-width: 767px) {
  .dash-parent {
    margin-top: 50px !important;
  }
}
.empty-text-parent {
}
.empty-text-child {
  font-weight: bold;
  color: #504747;
  opacity: 100 !important;
  position: absolute;
  top: 19%;
  padding: 10% 37%;
  z-index: 12;
  text-align: center;
}
.empty-text-child-1 {
  font-weight: bold;
  color: #504747;
  opacity: 100 !important;
  position: absolute;
  top: 19%;
  padding: 10% 9%;
  z-index: 12;
  text-align: center;
}
.dark-layout {
  .empty-text-child-1 {
    color: #ffffff;
  }
  .empty-text-child {
    color: #ffffff;
  }
  .apexcharts-menu.apexcharts-menu-open {
    background-color: var(--dark-bg) !important;
  }
}
.apexcharts-element-hidden {
  display: none;
}
</style>
