export default {
  type: "bar",
  name: "bar",

  series: [
    {
      name: "Invited",
      data: [0, 0, 0, 0, 0, 0, 0, 1, 0, 1]
    },
    {
      name: "Accepted",
      data: [1, 1, 1, 1, 1, 1, 1, 0, 1, 0]
    },
    {
      name: "Rejected",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: "Removed",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ],
  chartOptions: {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ["#26AB38", "#0000FF", "#FF6666", "#FBC60D"],
    plotOptions: {
      bar: {
        columnWidth: "15%",
        colors: {
          backgroundBarColors: [
            "#f8d3ff",
            "#f8d3ff",
            "#f8d3ff",
            "#f8d3ff",
            "#f8d3ff"
          ],
          backgroundBarRadius: 10
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      position: "top",
      fontSize: "14px",
      fontFamily: "Montserrat",
      horizontalAlign: "left"
    },
    stroke: {
      show: true,
      colors: ["transparent"]
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: [
        "29/02",
        "27/02",
        "16/02",
        "05/02",
        "25/01",
        "10/01",
        "09/01",
        "02/01",
        "26/12",
        "22/12"
      ]
    },
    yaxis: {},
    fill: {
      opacity: 1
    }
  }
};
