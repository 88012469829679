<template>
  <div>
    <b-badge :variant="variant" pill v-if="status_text"
      >{{ status_text }}
    </b-badge>
  </div>
</template>
<script>
import { BBadge } from "bootstrap-vue";

export default {
  components: {
    BBadge
  },

  props: ["status"],
  data() {
    return {
      variant: "primary",
      status_text: ""
    };
  },
  watch: {
    status(val) {
      this.updateStatus();
    }
  },
  mounted() {
    this.updateStatus();
  },
  methods: {
    updateStatus() {
      if (this.status === "ACTIVE") {
        this.variant = "primary";
        this.status_text = this.$t("Notification.Active");
      } else if (this.status === "INACTIVE") {
        this.variant = "danger";
        this.status_text = this.$t("Notification.InActive");
      } else if (this.status === "DAMAGED") {
        this.variant = "warning";
        this.status_text = this.$t("Notification.Damaged");
      } else if (this.status === "BLOCKED") {
        this.variant = "danger";
        this.status_text = this.$t("Notification.Blocked");
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
