export function customTooltipFunction({
  series,
  seriesIndex,
  dataPointIndex,
  w
}) {
  const formatDate = (date) => {
    const day = ("0" + date.getUTCDate()).slice(-2); // Get UTC day
    const month = date.toLocaleString("default", { month: "short" }); // Get abbreviated month name
    const year = date.getUTCFullYear(); // Get UTC full year
    const hours = ("0" + date.getUTCHours()).slice(-2); // Get UTC hours
    const minutes = ("0" + date.getUTCMinutes()).slice(-2); // Get UTC minutes
    const seconds = ("0" + date.getUTCSeconds()).slice(-2); // Get UTC seconds

    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  };
  const getDateTooltip = (seriesIndex, dataPointIndex) => {
    const date = new Date(w.config.series[seriesIndex].data[dataPointIndex][0]);
    const formattedDate = formatDate(date);
    return `
      <div class="tool-main-div">
        <div class="tool-title">
          <div class="tool-content">Date :</div>
          <span class="mr-5">${formattedDate}</span>
        </div>`;
  };

  const getSeriesTooltip = (index) => {
    const seriesData = w.config.series[index];

    let tooltipContent = `
      <div class="tool-main-content" >
        <div  class="tool-color-content" style="background:${
          seriesData.color
        }"></div>
        <div class="tool-content">${seriesData.name} :</div>
        <div style="margin-left:10px;">
          ${
            seriesData.config && seriesData.config.is_custom_tool
              ? seriesData.data[dataPointIndex][1]
                ? `<span class="tool-sub-content">${
                    seriesData.config.options?.[0] || "ON"
                  }</span>`
                : `<span class="tool-sub-content">${
                    seriesData.config.options?.[1] || "OFF"
                  }</span>`
              : seriesData.data[dataPointIndex][1]
          }
        </div>
      </div>`;

    if (
      seriesData.config &&
      seriesData.config.is_show_line &&
      seriesData.config.lines &&
      seriesData.config.lines.length
    ) {
      tooltipContent += seriesData.config.lines
        .map((line) => {
          const lineData = line.data[dataPointIndex] || 0;
          return `
          <div class="tool-main-content">
            <div class="tool-color-content" style="background:${
              line.color
            }"></div>
            <div class="tool-content">${line.name}:</div>
            <div style="margin-left:10px;">
              <span class="tool-sub-content">${lineData || "0"}</span>
            </div>
          </div>`;
        })
        .join("");
    }

    return tooltipContent;
  };

  let tooltipText = getDateTooltip(seriesIndex, dataPointIndex);
  tooltipText += series.map((_, index) => getSeriesTooltip(index)).join("");

  return tooltipText;
}
