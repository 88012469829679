export default {
  type: "table",
  name: "table",

  series: [
    {
      key: "date",
      label: "Date"
    },
    {
      key: "count",
      label: "Count"
    },
    {
      key: "avg_time",
      label: "Avg Time"
    },
    {
      key: "total_time",
      label: "Total Time"
    }
  ],

  chartOptions: [
    ({
      date: "08/04/2024",
      count: "3",
      total_time: "0 hours 21 minutes",
      avg_time: "0 hours 7 minutes"
    },
    {
      date: "07/04/2024",
      count: "7",
      total_time: "118 hours 29 minutes",
      avg_time: "16 hours 56 minutes"
    },
    {
      date: "05/04/2024",
      count: "3",
      total_time: "133 hours 38 minutes",
      avg_time: "44 hours 33 minutes"
    },
    {
      date: "04/04/2024",
      count: "3",
      total_time: "0 hours 33 minutes",
      avg_time: "0 hours 11 minutes"
    },
    {
      date: "03/04/2024",
      count: "9",
      total_time: "104 hours 2 minutes",
      avg_time: "11 hours 34 minutes"
    },
    {
      date: "02/04/2024",
      count: "7",
      total_time: "209 hours 22 minutes",
      avg_time: "29 hours 55 minutes"
    },
    {
      date: "29/03/2024",
      count: "7",
      total_time: "395 hours 35 minutes",
      avg_time: "56 hours 31 minutes"
    },
    {
      date: "28/03/2024",
      count: "6",
      total_time: "364 hours 54 minutes",
      avg_time: "60 hours 49 minutes"
    },
    {
      date: "27/03/2024",
      count: "3",
      total_time: "576 hours 18 minutes",
      avg_time: "192 hours 6 minutes"
    },
    {
      date: "26/03/2024",
      count: "1",
      total_time: "73 hours 5 minutes",
      avg_time: "73 hours 5 minutes"
    })
  ]
};
