export default {
  type: "line",
  name: "line",
  chartOptions: {
    colors: ["#8B4513", "#90EE90"],
    chart: {
      type: "line",
      animations: {
        enabled: false
      },

      zoom: {
        enabled: true,
        type: "x", // Enable x-axis zoom
        autoScaleYaxis: true, // Auto scale y-axis when zooming
        zoomedArea: {
          fill: {
            color: "#90CAF9", // Color of zoomed area
            opacity: 0.4 // Opacity of zoomed area
          }
        }
      }
    },
    markers: {
      size: 0
    },

    dataLabels: {
      enabled: false
    },

    xaxis: {
      type: "datetime"
    },
    stroke: {
      curve: "smooth",
      width: 2 // You can adjust the width of the stroke line here
      // You can set the curve type here
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value ? value.toFixed() : value; // Format y-axis labels as currency
        }
      }
    },
    tooltip: {},
    plotOptions: {},
    legend: {
      showForSingleSeries: true
    }
  },
  series: [
    {
      name: "Fuel Level",
      type: "line",
      config: {
        is_show_line: true,
        is_custom_tool: false,
        options: [],
        lines: [
          {
            color: "",
            name: "",
            data: []
          }
        ]
      },

      data: [
        [1710720480000, 10],
        [1710721080000, 20],
        [1710721680000, 30],
        [1710722280000, 32],
        [1710722880000, 43]
      ]
    }
  ]
};
