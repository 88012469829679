<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="table-responsive">
        <b-skeleton-table
          v-if="isSearching || isGroupListLoading || isUnitsLoading"
          :rows="10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />

        <b-table
          class="position-relative no-headers userlist-table"
          responsive
          v-if="!isUnitsLoading && !isSearching && !isGroupListLoading"
          show-empty
          align-v="end"
          :items="items"
          :fields="tableColumns"
          :tbody-tr-class="rowClass"
          @row-clicked="handleRowClick"
          :empty-text="$t('NoMatchingRecordsFound')"
        >
          <!-- Column: User -->
          <template #cell(name)="row">
            <div class="d-flex align-items-center">
              <span
                class="d-flex align-items-center ml-2"
                :id="row"
                :class="isGroupDisable ? 'disabled' : ''"
              >
                <b-form-checkbox
                  :disabled="isGroupDisable || isViewableOnly"
                  v-model="row.item.selected"
                  style="cursor: pointer"
                  @change="(e) => selectAll(e, row.item)"
                ></b-form-checkbox>
              </span>
              <b-media class="align-items-center ml-10">
                <template #aside>
                  <b-avatar
                    size="50"
                    :src="row.item.icon"
                    class="curserPointer"
                    @change="(e) => selectAll(e, row.item)"
                    :text="row.item.avtar_txt"
                    :variant="row.item.icon ? '' : row.item.variant"
                  />
                </template>
                <h6
                  class="mb-0 curserPointer"
                  v-if="row.item.source === 'GROUP'"
                >
                  {{ row.item.name }}
                </h6>
                <h6 v-else class="curserPointer">
                  {{ row.item.name }}
                </h6>
              </b-media>
            </div>
          </template>

          <template #cell(status)="row">
            <NotificationStatus :status="row.item.status" />
          </template>
          <template #cell(labels)="row">
            <div class="align-item-center event-Tag">
              <Badge
                :tags="row.item.labels.list"
                :showTextInBadge="Number(10)"
                :event="row.item"
                :maxTagShow="Number(7)"
              />
            </div>
          </template>
          <template #cell(units)="row">
            <div class="align-item-center event-Tag">
              <Badge
                :tags="row.item.units.list"
                :showTextInBadge="Number(10)"
                :event="row.item"
                :maxTagShow="Number(7)"
              />
            </div>
          </template>
        </b-table>
      </div>
      <div v-if="total > perPage" class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <SupportedUnitList
      :source="unitKey"
      :alertId="alertId"
      :groupId="groupId"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BFormCheckbox,
  BSkeletonTable,
  BLink
} from "bootstrap-vue";
import SupportedUnitList from "@/views/Notification/SupportedUnitList.vue";
import Badge from "@/layouts/components/Badge.vue";
import NotificationStatus from "@/views/Notification/NotificationStatus.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BSkeletonTable,
    BBadge,
    BPagination,
    Badge,
    BFormCheckbox,
    BLink,
    SupportedUnitList,
    NotificationStatus
  },

  data() {
    return {
      currentPage: 1,
      total: 0,
      items: [],
      unitKey: "ALL",
      perPage: 5,
      selectedItems: [],
      getSelectedUnselectedData: [],
      key: "",
      groupId: "",
      isGroupListLoading: false,
      isUnitsLoading: false
    };
  },

  props: [
    "getSelectedUnit",
    "pagination",
    "unitData",
    "tableColumns",
    "getSelectedUnSelectedUnits",
    "show",
    "alertId",
    "componentKey",
    "collectUnits",
    "toggleAllUnits",
    "isGroupDisable",
    "isViewableOnly",
    "isSearchLoading",
    "isSearching"
  ],

  mounted() {
    this.isGroupListLoading = true;
    this.netNewUnitData(this.unitData);
    if (["dashboard", "report"].includes(this.componentKey)) {
      this.perPage = 50;
    }
  },
  watch: {
    perPage() {
      if (!this.perPage) {
        this.perPage = 5;
      }
    },
    currentPage() {
      this.updatePagination();
    },
    unitData(val) {
      this.netNewUnitData(val);
    }
  },
  methods: {
    formattedDescriptions(description, len) {
      return description && description.length >= len
        ? `${description.slice(0, len).trim()}...`
        : description;
    },

    selectAll(e, data) {
      if (e) {
        this.selectedItems.push(data);
      } else {
        this.selectedItems = this.selectedItems.filter((e) => e.id != data.id);
      }
      this.getSelectedUnSelectedUnits(e, data, this.key);
      this.getSelectedUnit(this.selectedItems, this.key);
      if (["dashboard", "report"].includes(this.componentKey)) {
        this.collectUnits();
      }
      if (!!this.toggleAllUnits) {
        this.toggleAllUnits();
      }
    },
    updatePagination() {
      this.isUnitsLoading = true;
      this.pagination({
        perPage: this.perPage,
        currentPage: this.currentPage,
        key: this.key
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.id === this.alertId) return "table-primary-light";
    },
    handleRowClick(e) {
      let isSelectedUnit = false;
      this.items.forEach((element) => {
        if (element.id === e.id) {
          element.selected = !element.selected;
          isSelectedUnit = element.selected;
        }
      });
      this.selectAll(isSelectedUnit, e);
    },
    netNewUnitData(unit) {
      if (!unit) return;
      this.total = unit.total;
      this.items = unit.items;
      this.key = unit.key;
      this.isUnitsLoading = false;
      this.isGroupListLoading = false;
    }
  }
};
</script>
<style lang="scss">
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}
.group-width {
  text-align: right;
}
.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}
.ml-10 {
  margin-left: 10px;
}
.event-Tag {
  display: flex;
  flex-wrap: wrap;
}
.unit-td {
  padding-bottom: 5px;
  padding-right: 3px;
}
.disabled {
  pointer-events: none;

  cursor: not-allowed;
}
.disabled-report-icon {
  pointer-events: none;
  opacity: 0.5;
  // background-color: $input-disabled-bg;
}
</style>
