<template>
  <div class="main-vl">
    <div class="main-cl">
      <b-carousel
        id="my-carousel"
        v-model="slide"
        fade
        indicators
        :interval="8000"
        class="ss"
      >
        <b-carousel-slide :caption="$t('dashboard.SlideStep1')">
          <template #img>
            <div class="svg-rl">
              <Arrow1RTL v-if="isRTL" class="carousel-img" />
              <Arrow1 v-else class="carousel-img" />
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide :caption="$t('dashboard.SlideStep2')">
          <template #img>
            <div class="svg-rl">
              <Arrow2RTL v-if="isRTL" class="carousel-img" />
              <Arrow2 v-else class="carousel-img" />
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide :caption="$t('dashboard.SlideStep3')">
          <template #img>
            <div class="svg-rl">
              <Arrow3RTL v-if="isRTL" class="carousel-img" />
              <Arrow3 v-else class="carousel-img" />
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>
<script>
// Import Vue.js and BootstrapVue components
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import Arrow1 from "@/assets/images/icons/Arrow1.svg";
import Arrow2 from "@/assets/images/icons/Arrow2.svg";
import Arrow3 from "@/assets/images/icons/Arrow3.svg";
import Arrow1RTL from "@/assets/images/icons/Arrow1RTL.svg";
import Arrow2RTL from "@/assets/images/icons/Arrow2RTL.svg";
import Arrow3RTL from "@/assets/images/icons/Arrow3RTL.svg";
import { mapState } from "vuex";
import { $themeConfig } from "@themeConfig";

export default {
  data() {
    return {
      slide: 0,
      isRTL: false
    };
  },
  computed: {
    ...mapState("appConfig")
  },
  mounted() {
    const { isRTL } = $themeConfig.layout;
    this.isRTL = isRTL;
  },
  components: {
    BCarousel,
    BCarouselSlide,
    Arrow1,
    Arrow2,
    Arrow3,
    Arrow1RTL,
    Arrow2RTL,
    Arrow3RTL
  }
};
</script>
<style lang="scss">
.dark-layout {
  .carousel-img {
    background-color: #283046;
    border-radius: 10px;
  }
}
.carousel-img {
  background-color: white;
  border-radius: 10px;
}
.main-cl {
  width: 60%;
}
.main-vl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
.carousel-indicators li {
  background-color: var(--primary);
  margin-bottom: -12px;
}
.carousel-caption {
  text-shadow: none;
  left: 5% !important;
  right: 5% !important;
  h3 {
    font-size: 1rem;
    margin-bottom: -14px;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-weight: 400;
  }
}
</style>
